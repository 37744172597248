/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        $(document).ready(function() {

          new WOW().init();

        });

        // JavaScript to be fired on all pages
        $(window).on('scroll', function() {
            if ($(window).scrollTop() >= 100) {
                $('.header').addClass('fixed');
            } else {
                $('.header').removeClass('fixed');
            }
        });




        $(window).on('resize', function() {

            //Menu fixed
            if ($(window).scrollTop() >= 100) {
                $('.header').addClass('fixed');
            } else {
                $('.header').removeClass('fixed');
            }
            //Height slide principal
            $('#owl-example').css({
                height: $('body').height()
              });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        $("#desplegatransfer").on('click', function(e) {
          e.preventDefault();
          console.log("ebtrr");
          $('#transfer').toggleClass("appear");
        });


        $(".goto").on('click', function(e) {
          e.preventDefault();
          var scroll = $('#'+$(this).data("goto")).offset().top;
          if (scroll !== undefined) {
            $("html, body").animate({ scrollTop: scroll}, 2000);
          }
        });



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

                $("#owl-example").owlCarousel({
                  navigation: true,
                  singleItem: true,
                  slideSpeed : 300,
                  paginationSpeed : 400,
                  pagination: false,
                  rewindSpeed: 500,
                  autoPlay: 6000,
                });

                $('#myCarousel').carousel({
                    interval: 10000
                });

                $('.fdi-Carousel .item').each(function () {
                    var next = $(this).next();
                    if (!next.length) {
                        next = $(this).siblings(':first');
                    }
                    next.children(':first-child').clone().appendTo($(this));

                    if (next.next().length > 0) {
                        next.next().children(':first-child').clone().appendTo($(this));
                    }
                    else {
                        $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
                    }
                });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'entrenador_personal_zaragoza': {
      init: function() {
        // JavaScript to be fired on the about us page
        $("#owl-example").owlCarousel({
          navigation: true,
          singleItem: true,
          slideSpeed : 300,
          paginationSpeed : 400,
          pagination: false,
          rewindSpeed: 500,
          autoPlay: 6000,
        });

        $('#myCarousel').carousel({
            interval: 10000
        });

        $('.fdi-Carousel .item').each(function () {
            var next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));

            if (next.next().length > 0) {
                next.next().children(':first-child').clone().appendTo($(this));
            }
            else {
                $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
            }
        });

        function getStyles()
        {
          var styles = [
            {
              "stylers": [
              { "saturation": -50 }
              ]
            },{
              "featureType": "poi",
              "stylers": [
              { "visibility": "off" }
              ]
            },{
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
              { "visibility": "on" },
              { "lightness": 100 }
              ]
            },{
              "elementType": "labels.text.stroke",
              "stylers": [
              { "lightness": 100 }
              ]
            },{
              "featureType": "transit",
              "stylers": [
              { "visibility": "on" },
              { "gamma": 0.5 }
              ]
            },{
              "featureType": "water",
              "stylers": [

              { "visibility": "on" }
              ]
            },{
              "elementType": "labels.icon",
              "stylers": [
              { "visibility": "off" }
              ]
            },{
              "featureType": "road.arterial",
              "elementType": "geometry.stroke",
              "stylers": [
              { "lightness": -50 }
              ]
            },{
              "featureType": "poi",
              "stylers": [
              { "visibility": "simplified" },
              { "lightness": 57 }
              ]
            },{
              "elementType": "geometry.stroke",
              "stylers": [
              { "lightness": 44 },
              { "visibility": "simplified" }
              ]
            },{
              "featureType": "road.local",
              "elementType": "geometry.fill",
              "stylers": [
              { "lightness": 100 }
              ]
            },{
              "featureType": "transit.station",
              "stylers": [
              { "visibility": "on" },
              { "gamma": 9.91 }
              ]
            },{
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
              { "lightness": 100 }
              ]
            },{
              "featureType": "transit.line",
              "elementType": "geometry.fill",
              "stylers": [
              { "lightness": 100}
              ]
            }
          ];

          return styles;

        }

        function initMap(){

          var image = "/wp-content/themes/sage/dist/images/page-centro-contacto-pin.png";
          var parliament = new google.maps.LatLng(41.654461, -0.872161);

          map = new google.maps.Map(document.getElementById('map'), {
            center: {lat:41.654461, lng:-0.872161},
            scrollwheel:  false,
            zoom: 14,
            styles: getStyles()
          });

          marker = new google.maps.Marker({
            icon: image,
            map:map,
            draggable:false,
            animation: google.maps.Animation.DROP,
            position: parliament
          });

        }

        google.maps.event.addDomListener(window, 'load', function () { initMap(); });


        loadGallery(true, 'a.thumbnail');

        //This function disables buttons when needed
        function disableButtons(counter_max, counter_current){
          $('#show-previous-image, #show-next-image').show();
            if(counter_max == counter_current){
                $('#show-next-image').hide();
            } else if (counter_current == 1){
                $('#show-previous-image').hide();
            }
        }

        /**
         *
         * @param setIDs        Sets IDs when DOM is loaded. If using a PHP counter, set to false.
         * @param setClickAttr  Sets the attribute for the click handler.
         */

        function loadGallery(setIDs, setClickAttr){
            var current_image,
                selector,
                counter = 0;

            $('#show-next-image, #show-previous-image').click(function(){
                if($(this).attr('id') == 'show-previous-image'){
                    current_image--;
                } else {
                    current_image++;
                }

                selector = $('[data-image-id="' + current_image + '"]');
                updateGallery(selector);
            });

            function updateGallery(selector) {
                var $sel = selector;
                current_image = $sel.data('image-id');
                $('#image-gallery-caption').text($sel.data('caption'));
                $('#image-gallery-title').text($sel.data('title'));
                $('#image-gallery-image').attr('src', $sel.data('image'));
                disableButtons(counter, $sel.data('image-id'));
            }

            if(setIDs == true){
                $('[data-image-id]').each(function(){
                    counter++;
                    $(this).attr('data-image-id',counter);
                });
            }
            $(setClickAttr).on('click',function(){
                updateGallery($(this));
            });
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
